import basic from "@/models/basic"
import dbItem  from "@/teras/dbItem"

class eventoDocumento extends basic{
   constructor (args) {
      super(args)
        
      this.profile = new dbItem({table: "provider_evento_documento"})

      this._tipi= []

      return this
   }

   async new(){
      await super.new()

      this.profile.row.provider= this.provider.profile.row.id
      this.profile.row.providerevento= this.evento.profile.row.id
   }
   async load(id){
      await super.load(id)

      await this.loadDocumenti()
   }
   async loadEncode(id){
      await super.loadEncode(id)
   }
   async save(){
      if(this.profile.row.id==""){
         this.profile.row.caricatoil= this.luxon.now().toFormat("yyyy-MM-dd HH:mm:ss")
      }
      if(typeof this.profile.row.file!="string"){
         this.profile.row.file= JSON.stringify(this.profile.row.file)
      }
      this.profile.row.file= this.profile.row.file=="[]" ? "" : this.profile.row.file
      
      await super.save()
   }

   async elimina(id){
      await this.axios.query("doQuery", `UPDATE provider_evento_documento SET id= id*(-1) WHERE id= ←doc`, { doc: id })
   }
}

export default eventoDocumento