import main from "./main"

export class teras extends main{

   constructor (args) {
      super(args)

      this.name = "cpecs.eflm.eu"
      this.version = "1.0.1"
      this.urlsite = (process.env.NODE_ENV != "development") ? document.location.origin + "/" : ""

      this.apiUrl= "https://api.terasmm.it/201806/"
      this.mysql= {
         composer: "db",
         adapter: "contabo01-2023",
         database: "eflm_cpecs"
      }
      this.minWidthMobile= 760

      this.googleRecaptcha= {
         sw: "cpecs.eflm.eu",
         key: "6Ldn-akoAAAAAJ0DYsgI5BuHzs8uQ67-du9H-HE7"
      }
      this.stripe= {
         /*key: "pk_test_T4RtRGcixALXeLALc6oGbrzG",
         keyMode: "deTest"*/

         key: "pk_live_pyMXz2XPpjAPyy0MutnrhxIe",
         keyMode: "deReal"
      }

      this.defaultConvertOpt= { compact: true, ignoreComment: true, spaces: 3 }
      this.pageTitleBase= "EFLM CPECS®"

      this.manualeFilename= "EFLM-CPECS-Official-Manual-v3-2024.pdf"
      this.guidaspeackerchairFilename= "EFLM-CPECS-Chairs-and-Speakers-Guide-v1-2024.pdf"
      
      super.init()
      return this
   }

}
export default new teras
