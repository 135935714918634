import basic from "@/models/basic"
import dbItem  from "@/teras/dbItem"
import convert from 'xml-js'

class fiscale extends basic{
   constructor (args) {
      super(args)

      this.profile = new dbItem({ table: "fiscale" })

      return this
   }

   async new(tipo){
      await super.new()

      switch(tipo){
         case "fattura-evento":
            this.profile.row.documento= 1
            this.profile.row.area= 1
            this.profile.row.fatturarea= 1
            this.profile.row.fatturareaid= this.provider.profile.row.id
         break;
      }
      this.profile.row.step= 1
      this.profile.row.mezzo= 203
   }
   async load(id){
      await super.load(id)
   }
   async loadEncode(id){
      await super.loadEncode(id)
   }

   async save(){
      await super.save()
   }

   async finisciPagemento(importo, paycharge){
      this.profile.row.imponibile= importo
      this.profile.row.totale= importo
      this.profile.row.paycharge= paycharge
      this.profile.row.pagato= 1
      await this.save()
      
      //Genero i documenti
      return await this.generaFattura()
   }

   async generaFattura(idFattura){
      if(idFattura!=undefined){
         await this.load(idFattura)
      }

      var xml = convert.js2xml({ fiscale: this.teras.objmerge(this.profile.row, { datadocumento_display: this.luxon.fromSQL(this.profile.row.datadocumento).toFormat("dd MMMM yyyy"), mezzo_display: this.cercaElementoSelect(this.profile.row.mezzo, this._mezziPagamento, "id")[0].label_upper, datapagamento_display: this.luxon.fromSQL(this.profile.row.datapagamento).toFormat("dd MMMM yyyy") }) }, this.teras.defaultConvertOpt)

      var response= await this.axios.post(this.teras.apiUrl+"vue/pdf", {
         fopVersion: "2.8",
         pdf: this.calcolaPercorsoFattura(this.profile.row.id, "locale"),
         xsl: "/www/cpecs.eflm.eu/template/fattura.xsl",
         xml: xml
      })
      console.log(response)
      if(!response.data.data.esitogenerazione){
         var testoEmailErrore= "An error is occured while generating the invoice <strong>"+this.profile.row.numero+"</strong> ("+idFattura+")<br><ul>"
         for(var log of response.data.data.log){
            testoEmailErrore+= "<li>"+log+"</li>"
         }
         testoEmailErrore+= "</ul>"
         this.axios.email({ to: "mmm@terasmm.it", from: "eflm@eflm.eu", oggetto: "[EFLM-CPECS®] Error generating invoice PDF", body: testoEmailErrore })
         return { esito: false }
      }

      return { esito: true, url: this.calcolaPercorsoFattura(this.profile.row.id, "pubblico") }
   }
}

export default fiscale