import basic from "@/models/basic"
import dbItem  from "@/teras/dbItem"
import Email from "@/components/Email"

class evento extends basic{
   constructor (args) {
      super(args)
        
      this.profile = new dbItem({ table: "provider_evento" })
      this.documenti= []

      this._documentiTipi= []
      this._lingue= []
      this._livelliEducazione= []
      this._partecipantiLivello= []
      this._rilevamentopresenzeTipologie= []
      this._tipi= []

      this._giorniMinimiInvioRichiesta= 100

      return this
   }

   async new(){
      await super.new()

      this.profile.row.provider= this.provider.profile.row.id
      this.profile.row.partecipanti_percentualenational= 0
      this.profile.row.partecipanti_percentualeinternational= 0
      this.profile.row.durata= 0
      this.profile.row.partecipanti_tipologia= []

      this.documenti= []
   }
   async load(id){
      await super.load(id)
      this.profile.row.partecipanti_tipologia= this.profile.row.partecipanti_tipologia.toString().split(",").map(Number)

      await this.loadDocumenti()
   }
   async loadEncode(id){
      await super.loadEncode(id)
      this.profile.row.partecipanti_tipologia= this.profile.row.partecipanti_tipologia.toString().split(",").map(Number)

      await this.loadDocumenti()
   }
   async save(){
      console.log("SAVE evento")
      console.log(this.teras.copiaOggetto(this.profile.row))
      if(this.profile.row.id==""){
         this.profile.row.inseritoil= this.luxon.now().toFormat("yyyy-MM-dd HH:mm:ss")
      }

      this.profile.row.partecipanti_tipologia= Array.isArray(this.profile.row.partecipanti_tipologia) ? this.profile.row.partecipanti_tipologia.join(",") : this.profile.row.partecipanti_tipologia
      this.profile.row.file_partecipantielenco= typeof this.profile.row.file_partecipantielenco!="string" ? JSON.stringify(this.profile.row.file_partecipantielenco) : this.profile.row.file_partecipantielenco
      this.profile.row.file_evaluationform= typeof this.profile.row.file_evaluationform!="string" ? JSON.stringify(this.profile.row.file_evaluationform) : this.profile.row.file_evaluationform
      this.profile.row.file_certificatoesempio= typeof this.profile.row.file_certificatoesempio!="string" ? JSON.stringify(this.profile.row.file_certificatoesempio) : this.profile.row.file_certificatoesempio
      this.profile.row.file_presenze= typeof this.profile.row.file_presenze!="string" ? JSON.stringify(this.profile.row.file_presenze) : this.profile.row.file_presenze
      
      await super.save()

      this.profile.row.partecipanti_tipologia= this.profile.row.partecipanti_tipologia.toString().split(",").map(Number)
   }

   async loadDocumenti(){
      this.documenti= await this.loadDocumentiEvento(this.profile.row.id)
      for(var documento of this.documenti){
         var documentoTmp= this.buildFile(documento.nome, documento.file)
         documento.url= documentoTmp.url
         documento.name= documentoTmp.name
         documento.extensionimg= documentoTmp.extensionimg
         documento.extension= documentoTmp.extension
      }
   }

   async calcolaPrezzoDaPagare(){
      var eventoListinoTipo= "LE"
      if([4].includes(this.profile.row.tipo)){
         eventoListinoTipo= "DE"
      }
      var listino= await this.axios.query("fetchRow", 
         `SELECT id, prezzo, prezzo_eflm 
         FROM _provider_evento_listinoattivo
         WHERE evento_tipo= →evtipo AND ←evore>=durata_min AND ←evore<=durata_max`, { evtipo: eventoListinoTipo, evore: this.profile.row.durata })
      
      if(listino.result.id==undefined){
         await this.axios.email({ to: "mmm@terasmm.it", from: "eflm@eflm.eu", oggetto: "[ERROR] EFLM-CPECS® - Event price list", body: "Impossibile determinare l'importo da pagare per l'evento "+this.profile.row.id })
         return -1
      }

      if([1].includes(this.provider.profile.row.tipo)){
         return listino.result.prezzo_eflm
      }

      return listino.result.prezzo
   }

   async trovaValutatore(){
      var valutatori= await this.axios.query("fetchAll", 
         `SELECT id, nome, cognome, email
         FROM utente
         WHERE id>0 AND stato= 1 AND providerevento_valutazione= 1 AND nazione<>→naz`, { naz: this.provider.profile.row.sedelegale_nazione })
      if(valutatori.result.length==0){
         await this.axios.email({ to: "mmm@terasmm.it", from: "eflm@eflm.eu", oggetto: "[ERROR] EFLM-CPECS® - Event evaluator", body: "Nessun valutatore di eventi trovato per l'evento "+this.profile.row.id+" della nazione "+this.provider.profile.row.sedelegale_nazione })
         return {}
      }

      var indexValutatore= this.teras.randomWithBoundaries(0, valutatori.result.length-1)
      return valutatori.result[indexValutatore]
   }
   trovaStepValutazione(){
      if(this.profile.row.valutazione1_esito==0){
         return "1"
      } else if(this.profile.row.valutazione1_esito==3 && this.profile.row.valutazione2_esito==0){
         return "2"
      } else if(this.profile.row.valutazione1_esito==3 && this.profile.row.valutazione2_esito==3 && this.profile.row.valutazione3_esito==0){
         return "3"
      }

      return ""
   }
   async registraRevisione(stepRevisione, oldRow, newRow, oldDocs, newDocs){
      //Registro la revisione solo per avere i log di com'era prima la richiesta dell'evento e com'è al momento dell'invio della revisione (non viene mostrata da nessuna parte)
      var eventoRevisione= new dbItem({ table: "provider_evento_revisione" })
      await eventoRevisione.new()
      eventoRevisione.row.provider= newRow.provider
      eventoRevisione.row.providerevento= newRow.id
      eventoRevisione.row.revisione_step= stepRevisione
      eventoRevisione.row.revisione_data= this.luxon.now().toFormat("yyyy-MM-dd HH:mm:ss")
      eventoRevisione.row.oldrow= typeof oldRow!="string" ? JSON.stringify(oldRow) : oldRow
      eventoRevisione.row.newrow= typeof newRow!="string" ? JSON.stringify(newRow) : newRow
      eventoRevisione.row.olddocs= typeof oldDocs!="string" ? oldDocs.join(",") : oldDocs
      eventoRevisione.row.newdocs= typeof newDocs!="string" ? newDocs.join(",") : newDocs
      await eventoRevisione.save()
   }
   async completaRichiesta(emailParams){
      if(this.profile.row.valutazione1_utente==0 || this.profile.row.valutazione2_utente==0 || this.profile.row.valutazione3_utente==0){
         var valutatoreEvento= await this.trovaValutatore()
         if(valutatoreEvento.id==undefined){
            return false;
         }

         if(this.profile.row.valutazione1_utente==0){
            this.profile.row.valutazione1_utente= valutatoreEvento.id
         }
         if(this.profile.row.valutazione2_utente==0){
            this.profile.row.valutazione2_utente= valutatoreEvento.id
         }
         if(this.profile.row.valutazione3_utente==0){
            this.profile.row.valutazione3_utente= valutatoreEvento.id
         }
      }
      var stepValutazione= this.trovaStepValutazione()
      if(stepValutazione==""){
         return false;
      }

      //Se è il primo invio che sto facendo imposto la data di invio in modo da rendere la richiesta visualizzabile nell'interfaccia di amministrazione
      if(stepValutazione=="1"){
         this.profile.row.inviatoil= this.luxon.now().toFormat("yyyy-MM-dd HH:mm:ss")
      } else if(stepValutazione=="2"){
         this.profile.row.inviatoil_revisione1= this.luxon.now().toFormat("yyyy-MM-dd HH:mm:ss")
      } else if(stepValutazione=="3"){
         this.profile.row.inviatoil_revisione2= this.luxon.now().toFormat("yyyy-MM-dd HH:mm:ss")
      }
      this.profile.row["valutazione"+stepValutazione+"_esito"]= 1
      
      await this.save()
      await this.inviaEmailCompletamento(stepValutazione, emailParams)

      return true
   }
   async inviaEmailCompletamento(stepValutazione, emailParams){
      var destinatari= process.env.NODE_ENV == "development" ? [] : [ "eflm@eflm.eu", "mmm@terasmm.it" ]
      var idValutatore= this.profile.row["valutazione"+stepValutazione+"_utente"]

      var valutatore= await this.axios.query("fetchRow", `SELECT id, email, nome, cognome FROM utente WHERE id= ←val`, { val: idValutatore })

      var destinatariValutarore= destinatari
      destinatariValutarore.push(valutatore.result.email)
      var sezioneEmailValutatore= "providerevento-invio-cpecsoffice", oggettoEmailValutatore= "New event to evaluate"
      if(stepValutazione=="2"){
         sezioneEmailValutatore= "providerevento-primamodifica-cpecsoffice"
         oggettoEmailValutatore= "1st revision of the submitted event"
      } else if(stepValutazione=="3"){
         sezioneEmailValutatore= "providerevento-secondamodifica-cpecsoffice"
         oggettoEmailValutatore= "2nd revision of the submitted event"
      }
      //Invio la mail al valutatore
      var bodyEmail= await this.teras.renderVue(Email, { sezione: sezioneEmailValutatore, evento: { nome: this.profile.row.nome, educationaladmin_email: this.provider.profile.row.educationaladmin_email, provider_nome: this.provider.profile.row.nome }, cpecsOfficer: valutatore.result })
      await this.axios.email({ to: destinatariValutarore.join("::"), from: process.env.NODE_ENV == "development" ? "mattia@terasmm.it" : "eflm@eflm.eu", oggetto: "EFLM-CPECS® - "+oggettoEmailValutatore, body: bodyEmail })

      if(stepValutazione=="1"){
         var destinatariProvider= destinatari
         //destinatariProvider.push(this.provider.profile.row.educationaladmin_email+","+this.profile.row.coordinatore_email)
         destinatariProvider.push(this.provider.profile.row.educationaladmin_email+(this.profile.row.coordinatore_email!=this.provider.profile.row.educationaladmin_email ? ","+this.profile.row.coordinatore_email : ""))
         //Invio la mail al provider
         bodyEmail= await this.teras.renderVue(Email, { sezione: "providerevento-invio", evento: this.teras.objmerge({ nome: this.profile.row.nome }, emailParams!=undefined ? emailParams : {}) })
         await this.axios.email({ to: destinatariProvider.join("::"), from: process.env.NODE_ENV == "development" ? "mattia@terasmm.it" : "eflm@eflm.eu", oggetto: "EFLM-CPECS® - Event submitted under evaluation", body: bodyEmail })
      }
   }

   async inviaDocumentiPostevento(){
      await this.save()

      var destinatari= process.env.NODE_ENV == "development" ? [] : [ "eflm@eflm.eu", "mmm@terasmm.it" ]
      var idValutatore= ""
      for(var i=1; i<=3; i++){
         if(this.profile.row["valutazione"+i.toString()+"_esito"]==6){
            idValutatore= this.profile.row["valutazione"+i.toString()+"_utente"]
            break;
         }
      }
      var valutatore= await this.axios.query("fetchRow", `SELECT id, email, nome, cognome FROM utente WHERE id= ←val`, { val: idValutatore })
      destinatari.push(valutatore.result.email)
   
      //Invio la mail al valutatore
      var bodyEmail= await this.teras.renderVue(Email, { sezione: "postevento-report", evento: { nome: this.profile.row.nome, educationaladmin_email: this.provider.profile.row.educationaladmin_email, provider_nome: this.provider.profile.row.nome }, cpecsOfficer: valutatore.result })
      await this.axios.email({ to: destinatari.join("::"), from: process.env.NODE_ENV == "development" ? "mattia@terasmm.it" : "eflm@eflm.eu", oggetto: "EFLM-CPECS® - Post-event report", body: bodyEmail })
   }
}

export default evento