<template>
   <table ref="body" align="center" style="width: 800px; margin: 40px auto; background: #FFFFFF; border: 1px solid #353535; border-spacing: 0; border-collapse: collapse;">
      <tr>
         <td>
            <table width="800" cellpadding="0" cellspacing="0" border="0">
               <tr>
                  <td style="text-align: center; padding: 24px 16px;"><img :src="'https://cpecs.eflm.eu/images/logo-email.png'" width="800" height="83" /></td>
               </tr>
               <tr>
                  <td style="padding: 16px 24px">
                     <div v-if="['provider-registrazione-cpecsoffice'].includes(sezione)">
                        <h3 style="text-align: center; font-size: 3.0rem; font-size: 40px; line-height: 130%; margin-block-start: 0; color: #FE9400;">New provider application</h3>
                        <p>Dear CPECS Officer ({{ cpecsOfficer.nome }} {{ cpecsOfficer.cognome }}),</p>
                        <p>
                           This is to inform you that <strong>{{ provider.nome }}</strong> has submitted an application to become a Provider accredited by the EFLM-CPECS<sup>®</sup> system.
                        </p>
                        <p>
                           Please click the below link to evaluate the application as soon as possible within 30 calendar days.
                        </p>

                        <div style="text-align: center;">
                           <a href="https://cpecs.eflm.eu/backoffice" target="_blank" style="margin: 0; padding: 0;">
                              <table role="presentation" border="0" cellspacing="0" cellpadding="0" width="auto" align="center"> 
                                 <tbody> 
                                    <tr> 
                                       <td bgcolor="#1D61AF" style="border-color: #1D61AF; background-color: #1D61AF; padding: 12px 24px; color: #FFFFFF; display: inline-block; text-decoration: none; margin: 0 auto; font-size:20px; line-height: 1.25em; border-radius: 4px; border-width: 1px; border-style: solid">
                                          <a href="https://cpecs.eflm.eu/backoffice" target="_blank" style="color: #FFFFFF;">EVALUATE</a>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </a>
                        </div><br /><br />

                        <p style="text-align: center"><strong>IMPORTANT - Please remember that your evaluation should be made exclusively via the platform!</strong></p>
                        <p>In case you need to contact the applicant to have further information on the provided documentation, the applicant’s e-mail is: <a :href="'mailto:'+provider.educationaladmin_email">{{ provider.educationaladmin_email }}</a>.</p>
                     </div>
                     <div v-else-if="['provider-registrazione'].includes(sezione)">
                        <h3 style="text-align: center; font-size: 3.0rem; font-size: 40px; line-height: 130%; margin-block-start: 0; color: #FE9400;">Provider application successfully submitted</h3>
                        <p>Dear Applicant,</p>
                        <p>
                           Your application to become a Provider accredited by the EFLM-CPECS<sup>®</sup> credits system has been successfully submitted.
                        </p>
                        <p>
                           The evaluation of your application can take up to 30-40 calendar days to be completed. During this time if necessary, you could be asked by the CPECS Officer to add some further information in the platform. While, if the entered information is already satisfactory, you will receive an e-mail confirming the status as “Provider accredited by the EFLM-CPECS<sup>®</sup> credits system”. In this case, a Provider identification number will be generated to be indicated on all your future educational events submitted for CPECS<sup>®</sup>.
                        </p>
                        <div>
                           The personal credentials to access your dashboard are the one you set during the application process. If you do not remember them, please go to the <a href="https://cpecs.eflm.eu/provider/reset-password" target="_blank">reset password page</a>.
                        </div>
                        <p>
                           Please note that the submission of educational events to be granted with CPECS<sup>®</sup> is only possible <span style="text-decoration: underline;">after notification of successful registration</span> as a Provider accredited by the EFLM-CPECS<sup>®</sup> credits system.
                        </p>
                     </div>
                     <div v-else-if="['provider-registrazione-reinvio'].includes(sezione)">
                        <h3 style="text-align: center; font-size: 3.0rem; font-size: 40px; line-height: 130%; margin-block-start: 0; color: #FE9400;">Revision of the application as Provider</h3>
                        <p>Dear CPECS Officer ({{ cpecsOfficer.nome }} {{ cpecsOfficer.cognome }}),</p>
                        <p>
                           This is to inform you that <strong>{{ provider.nome }}</strong> has revised the provider application according to your recommendation.
                        </p>
                        <p>
                           Please click the below link to evaluate the application as soon as possible within 10 calendar days.
                        </p>
                        <div style="text-align: center;">
                           <a href="https://cpecs.eflm.eu/backoffice" target="_blank" style="margin: 0; padding: 0;">
                              <table role="presentation" border="0" cellspacing="0" cellpadding="0" width="auto" align="center"> 
                                 <tbody> 
                                    <tr> 
                                       <td bgcolor="#1D61AF" style="border-color: #1D61AF; background-color: #1D61AF; padding: 12px 24px; color: #FFFFFF; display: inline-block; text-decoration: none; margin: 0 auto; font-size:20px; line-height: 1.25em; border-radius: 4px; border-width: 1px; border-style: solid">
                                          <a href="https://cpecs.eflm.eu/backoffice" target="_blank" style="color: #FFFFFF;">EVALUATE</a>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </a>
                        </div><br /><br />
                        <p style="text-align: center"><strong>IMPORTANT - Please remember that your evaluation should be made exclusively via the platform!</strong></p>
                        <p>In case you need to contact the applicant to have further information on the provided documentation, the applicant’s e-mail is: <a :href="'mailto:'+provider.educationaladmin_email">{{ provider.educationaladmin_email }}</a>.</p>
                     </div>
                     <div v-else-if="['provider-modifica-educationaladmin'].includes(sezione)">
                        <h3 style="text-align: center; font-size: 3.0rem; font-size: 40px; line-height: 130%; margin-block-start: 0; color: #FE9400;">Replacement of the Educational Events Administrator</h3>
                        <p>Dear CPECS Officer ({{ cpecsOfficer.nome }} {{ cpecsOfficer.cognome }}),</p>
                        <p>
                           This is to inform you that <strong>{{ provider.nome }}</strong> has replaced the Educational Events Administrator.
                        </p>
                        <p>
                           Below the details of the replacement:
                        </p>
                        <ul>
                           <li>Name of the new Educational Events Administrator: <strong>{{ provider.educationaladmin_nome }} {{ provider.educationaladmin_cognome }}</strong></li>
                           <li><a :href="'https://cpecs.eflm.eu/upload/'+provider.educationaladmin_cv[0].url" target="_blank">Curriculum Vitae of the new Education Events Administrator</a></li>
                           <li><a :href="'https://cpecs.eflm.eu/upload/'+provider.educationaladmin_liberatoria[0].url" target="_blank">Declaration signed by the new Education Events Administrator </a></li>
                        </ul>
                        <p>In case you need to contact the applicant to have further information on the provided documentation, the applicant’s e-mail is: <a :href="'mailto:'+provider.educationaladmin_email">{{ provider.educationaladmin_email }}</a>.</p>
                     </div>

                     <div v-else-if="['providerevento-invio-cpecsoffice'].includes(sezione)">
                        <h3 style="text-align: center; font-size: 3.0rem; font-size: 40px; line-height: 130%; margin-block-start: 0; color: #FE9400;">New event to evaluate</h3>
                        <h5 style="text-align: center; font-size: 2.0rem; font-size: 28px; line-height: 130%; margin-block-start: 0; color: #00589a;">Event: <strong>{{ evento.nome }}</strong></h5>
                        <p>Dear CPECS Officer ({{ cpecsOfficer.nome }} {{ cpecsOfficer.cognome }}),</p>
                        <p>
                           This is to inform you that <strong>{{ evento.provider_nome }}</strong> has submitted the above-mentioned Educational Event to obtain CPECS<sup>®</sup>. 
                        </p>
                        <p>
                           Please click the below link to evaluate the event as soon as possible within 45 calendar days. 
                        </p>
                        <div style="text-align: center;">
                           <a href="https://cpecs.eflm.eu/backoffice" target="_blank" style="margin: 0; padding: 0;">
                              <table role="presentation" border="0" cellspacing="0" cellpadding="0" width="auto" align="center"> 
                                 <tbody> 
                                    <tr> 
                                       <td bgcolor="#1D61AF" style="border-color: #1D61AF; background-color: #1D61AF; padding: 12px 24px; color: #FFFFFF; display: inline-block; text-decoration: none; margin: 0 auto; font-size:20px; line-height: 1.25em; border-radius: 4px; border-width: 1px; border-style: solid">
                                          <a href="https://cpecs.eflm.eu/backoffice" target="_blank" style="color: #FFFFFF;">EVALUATE</a>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </a>
                        </div><br /><br />

                        <p style="text-align: center"><strong>IMPORTANT - Please remember that your evaluation should be made exclusively via the platform!</strong></p>
                        <p>In case you need to contact the Educational Events Coordinator to have further information on the provided documentation, the e-mail is: <a :href="'mailto:'+evento.educationaladmin_email">{{ evento.educationaladmin_email }}</a>.</p>
                     </div>
                     <div v-else-if="['providerevento-invio'].includes(sezione)">
                        <h3 style="text-align: center; font-size: 3.0rem; font-size: 40px; line-height: 130%; margin-block-start: 0; color: #FE9400;">Event submitted successfully</h3>
                        <h5 style="text-align: center; font-size: 2.0rem; font-size: 28px; line-height: 130%; margin-block-start: 0; color: #00589a;">Event: <strong>{{ evento.nome }}</strong></h5>
                        <p>Dear Applicant,</p>
                        <p>
                           Your event has been correctly submitted and will shortly undergo evaluation.
                        </p>
                        <p>
                           The evaluation of your application can take up to 100 calendar days to be completed. If the entered information is already satisfactory, you will be notified with an e-mail containing the granted number of CPECS<sup>®</sup> and further instruction on the CPECS<sup>®</sup> requirements during and after the event. 
                        </p>
                        <p>
                           However, you may also be asked by the CPECS<sup>®</sup> Office to add some further information in the platform.
                        </p>
                        <p v-if="![undefined, ''].includes(evento.fattura_link)">
                           We confirm also the receiving of the payment of the CPECS<sup>®</sup> fees, click <a :href="evento.fattura_link" target="_blank">here</a> to download the invoice.
                        </p>
                     </div>
                     <div v-else-if="['providerevento-primamodifica-cpecsoffice'].includes(sezione)">
                        <h3 style="text-align: center; font-size: 3.0rem; font-size: 40px; line-height: 130%; margin-block-start: 0; color: #FE9400;">1st revision of the submitted event</h3>
                        <h5 style="text-align: center; font-size: 2.0rem; font-size: 28px; line-height: 130%; margin-block-start: 0; color: #00589a;">Event: <strong>{{ evento.nome }}</strong></h5>
                        <p>Dear CPECS Officer ({{ cpecsOfficer.nome }} {{ cpecsOfficer.cognome }}),</p>
                        <p>
                           This is to inform you that <strong>{{ evento.provider_nome }}</strong> has revised the above-mentioned event according to your recommendation. 
                        </p>
                        <p>
                           Please click the below link to evaluate the event as soon as possible mandatory within 14 calendar days.
                        </p>
                        <div style="text-align: center;">
                           <a href="https://cpecs.eflm.eu/backoffice" target="_blank" style="margin: 0; padding: 0;">
                              <table role="presentation" border="0" cellspacing="0" cellpadding="0" width="auto" align="center"> 
                                 <tbody> 
                                    <tr> 
                                       <td bgcolor="#1D61AF" style="border-color: #1D61AF; background-color: #1D61AF; padding: 12px 24px; color: #FFFFFF; display: inline-block; text-decoration: none; margin: 0 auto; font-size:20px; line-height: 1.25em; border-radius: 4px; border-width: 1px; border-style: solid">
                                          <a href="https://cpecs.eflm.eu/backoffice" target="_blank" style="color: #FFFFFF;">EVALUATE</a>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </a>
                        </div><br /><br />

                        <p style="text-align: center"><strong>IMPORTANT - Please remember that your evaluation should be made exclusively via the platform!</strong></p>
                        <p>In case you need to contact the Educational Events Coordinator to have further information on the provided documentation, the e-mail is: <a :href="'mailto:'+evento.educationaladmin_email">{{ evento.educationaladmin_email }}</a>.</p>
                     </div>
                     <div v-else-if="['providerevento-secondamodifica-cpecsoffice'].includes(sezione)">
                        <h3 style="text-align: center; font-size: 3.0rem; font-size: 40px; line-height: 130%; margin-block-start: 0; color: #FE9400;">2nd revision of the submitted event</h3>
                        <h5 style="text-align: center; font-size: 2.0rem; font-size: 28px; line-height: 130%; margin-block-start: 0; color: #00589a;">Event: <strong>{{ evento.nome }}</strong></h5>
                        <p>Dear CPECS Officer ({{ cpecsOfficer.nome }} {{ cpecsOfficer.cognome }}),</p>
                        <p>
                           This is to inform you that <strong>{{ evento.provider_nome }}</strong> has revised the above-mentioned event according to your recommendation. 
                        </p>
                        <p>
                           Please click the below link to evaluate the event as soon as possible mandatory within 7 calendar days.
                        </p>
                        <div style="text-align: center;">
                           <a href="https://cpecs.eflm.eu/backoffice" target="_blank" style="margin: 0; padding: 0;">
                              <table role="presentation" border="0" cellspacing="0" cellpadding="0" width="auto" align="center"> 
                                 <tbody> 
                                    <tr> 
                                       <td bgcolor="#1D61AF" style="border-color: #1D61AF; background-color: #1D61AF; padding: 12px 24px; color: #FFFFFF; display: inline-block; text-decoration: none; margin: 0 auto; font-size:20px; line-height: 1.25em; border-radius: 4px; border-width: 1px; border-style: solid">
                                          <a href="https://cpecs.eflm.eu/backoffice" target="_blank" style="color: #FFFFFF;">EVALUATE</a>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </a>
                        </div><br /><br />
                        
                        <p style="text-align: center"><strong>IMPORTANT - Please remember that your evaluation should be made exclusively via the platform!</strong></p>
                        <p>In case you need to contact the Educational Events Coordinator to have further information on the provided documentation, the e-mail is: <a :href="'mailto:'+evento.educationaladmin_email">{{ evento.educationaladmin_email }}</a>.</p>
                     </div>

                     <div v-else-if="['postevento-report'].includes(sezione)">
                        <h3 style="text-align: center; font-size: 3.0rem; font-size: 40px; line-height: 130%; margin-block-start: 0; color: #FE9400;">Post-event report</h3>
                        <h5 style="text-align: center; font-size: 2.0rem; font-size: 28px; line-height: 130%; margin-block-start: 0; color: #00589a;">Event: <strong>{{ evento.nome }}</strong></h5>
                        <p>Dear CPECS Officer ({{ cpecsOfficer.nome }} {{ cpecsOfficer.cognome }}),</p>
                        <p>
                           This is to inform you that <strong>{{ evento.provider_nome }}</strong> entered the requested documentation for the post event report for the above-mentioned event.
                        </p>
                        <p>
                           Please click the below link to view the documentation.
                        </p>
                        <div style="text-align: center;">
                           <a href="https://cpecs.eflm.eu/backoffice" target="_blank" style="margin: 0; padding: 0;">
                              <table role="presentation" border="0" cellspacing="0" cellpadding="0" width="auto" align="center"> 
                                 <tbody> 
                                    <tr> 
                                       <td bgcolor="#1D61AF" style="border-color: #1D61AF; background-color: #1D61AF; padding: 12px 24px; color: #FFFFFF; display: inline-block; text-decoration: none; margin: 0 auto; font-size:20px; line-height: 1.25em; border-radius: 4px; border-width: 1px; border-style: solid">
                                          <a href="https://cpecs.eflm.eu/backoffice" target="_blank" style="color: #FFFFFF;">POST-EVENT REPORT</a>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </a>
                        </div><br /><br />
                        
                        <p style="text-align: center"><strong>IMPORTANT - Please remember to endorse the documentation exclusively via the platform!</strong></p>
                        <p>In case you need to contact the Educational Events Administrator to have further information on the provided documentation, the e-mail is: <a :href="'mailto:'+evento.educationaladmin_email">{{ evento.educationaladmin_email }}</a>.</p>
                     </div>

                     <div v-else-if="['reset-password'].includes(sezione)">
                        <h3 style="text-align: center; font-size: 3.0rem; font-size: 40px; line-height: 130%; margin-block-start: 0; color: #FE9400;">Reset Password Request</h3>
                        Dear {{ provider.educationaladmin_nome }} {{ provider.educationaladmin_cognome }}<br /><br />
                        We have received a request to reset the password of your EFLM-CPECS<sup>®</sup> account.<br />

                        <table cellmargin="0" cellspacing="8" width="800" border="0" align="center" style="border: none; padding: 24px;">
                           <tr>
                              <td width="40%"><div style="text-align: center;">YOUR RESET LINK</div></td>
                              <td width="60%"><a :href="provider.linkReset" style="border: 0">Click here to reset your password</a></td>    
                           </tr>
                        </table>

                        We remind you that for security reasons the link is valid only for <strong>24 hours</strong>, after that you need to request a <a href="https://cpecs.eflm.eu/reset-password" target="_blank">new reset</a> of the password.<br /><br />
                        If you have any problem please <a href="mailto:eflm@eflm.eu">contact the EFLM office</a>
                     </div>
                  </td>
               </tr>
               <tr> 
                  <td style="text-align: center; background: #666666; color: #FFFFFF; padding: 24px;">
                     <span style="color: #FFFFFF">
                        European Federation of Clinical Chemistry and Laboratory Medicine (EFLM)<br />
                        e-mail: <a href="mailto:eflm@eflm.eu" style="color: #FFFFFF; font-weight: bold;">eflm@eflm.eu</a> - website: <a href="https://www.eflm.eu" target="_blank" style="color: #FFFFFF; font-weight: bold;">www.eflm.eu</a>
                     </span>
                  </td>    
               </tr>
            </table>
         </td>
      </tr>
   </table>
</template>

<script>
export default {
   props: {
      sezione: {
         type: String,
         required: true
      },
      provider: {
         type: Object
      },
      evento: {
         type: Object
      },
      cpecsOfficer: {
         type: Object
      }
   }
}
</script>