const EventEmitter = require("events").EventEmitter

import axios from "./tAxios"
import aes from "./aes"

class dbItem {
   constructor (args) {
      this.event = new EventEmitter()
      this.axios = axios
      this.aes = aes
      console.log(args)
      this.mysql =  this.objmerge(axios.mysql,this.objmerge({
         table:"tabellanonesistente",
         primary:"id"
      },args))
      this.row = {}
      this.rowIsNew = false
      return this
   }

   objmerge(){
      var objReturn = {}
      var prop 
      if(arguments.length>0) for (prop in arguments[0]) objReturn[prop] = arguments[0][prop]
      if(arguments.length>1) for (prop in arguments[1]) objReturn[prop] = arguments[1][prop]
      if(arguments.length>2) for (prop in arguments[2]) objReturn[prop] = arguments[2][prop]
      if(arguments.length>3) for (prop in arguments[3]) objReturn[prop] = arguments[3][prop]
      return objReturn
   }

   async new(){
      return new Promise((resolve, reject) => {
         this.row = {}
         var mySql = this.objmerge(this.mysql,{id:""})
         this.axios.post(this.axios.apiUrl+this.axios.mysql.composer+"/load",mySql).then((response) => {
               this.rowIsNew = response.data.data.isnew
               var api = response.data
               this.row = api.data.row
               resolve(api.data)
         }).catch((error) => {reject(error)})
      })
   }

   loadEncode(encodeId){
      return new Promise((resolve, reject) => {
         this.load(this.aes.decode(encodeId)).then((response) => {
               resolve(response)
         })
      })
   }
   async load(id){
      return new Promise((resolve, reject) => {
         this.row = {}
         this.rowIsNew = false
         var mySql = this.objmerge(this.mysql,{id:id})
         this.axios.post(this.axios.apiUrl+this.axios.mysql.composer+"/load",mySql).then((response) => {
            this.rowIsNew = response.data.data.isnew
            this.row = response.data.data.row
            //this.event.emit("loaded",this.row)
            resolve(response.data.data )
         }).catch((error) => {reject(error)})
      })
   }

   async save(){
      return new Promise((resolve, reject) => {
         var mySql = this.objmerge(this.mysql,{row:this.row})
         if(arguments.length>0) mySql = this.objmerge(mySql,arguments[0])
         this.axios.post(this.axios.apiUrl+this.axios.mysql.composer+"/save",mySql).then((response) => {
            var api = response.data
            this.row = api.data.row
            this.event.emit("saved",api.data)
            console.log("FINITO SAVE")
            resolve(api.data)
         }).catch((error) => {reject(error)}) 
      })
   }

   delete(id){
      return new Promise((resolve, reject) => {
         var mySql = this.objmerge(this.mysql,{id:id})
         this.axios.post(this.axios.apiUrl+this.axios.mysql.composer+"/delete",mySql).then((response) => {
            var api = response.data
            this.row = {}
            this.event.emit("deleted",api.data)
            resolve(api.data)
         }).catch((error) => {reject(error)}) 
      })
   }

   update(id,campo,value){
      return new Promise((resolve, reject) => {
         this.load(id).then(() => {
            console.log("LOADED LOADED LOADED "+this.row.id)
            this.row[campo] = value
            this.save().then(() => {
               resolve(this.row)
            })
         })
      })
   }
}
export default dbItem