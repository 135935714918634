import basic from "@/models/basic"
import dbItem  from "@/teras/dbItem"
import Email from "@/components/Email"

class provider extends basic{
   constructor (args) {
      super(args)
        
      this.profile = new dbItem({table: "provider"})
      this.eventi= []

      this._tipiEsterni= []
      this._tipiInterni= []
      this._pathFiles= "cpecs.eflm.eu/upload"

      return this
   }
   async checkCampoUnivoco(nomeCampo, valoreCampo){
      var condId= this.profile.row.id=="" ? "" : " AND id<>←id"
      if(valoreCampo==undefined){
         valoreCampo= this.profile.row[nomeCampo]
      }
      var checkCampo= await this.axios.query("fetchRow", 
         `SELECT id FROM provider WHERE id>0 AND ←campo= →valcampo`+condId, { id: this.profile.row.id, campo: nomeCampo, valcampo: valoreCampo })
      return checkCampo.result.id==undefined
   }

   async new(){
      await super.new()

      this.profile.row.stato= 0
      this.profile.row.esterno= 1
   }
   async load(id){
      await super.load(id)

      await this.elencoEventi()
   }
   async loadEncode(id){
      await super.loadEncode(id)

      await this.elencoEventi()
   }
   async save(){
      if(this.profile.row.id==""){
         this.profile.row.inseritoil= this.luxon.now().toFormat("yyyy-MM-dd HH:mm:ss")
      }
      
      this.profile.row.statuto= typeof this.profile.row.statuto!="string" ? JSON.stringify(this.profile.row.statuto) : this.profile.row.statuto
      this.profile.row.statuto_inglese= typeof this.profile.row.statuto_inglese!="string" ? JSON.stringify(this.profile.row.statuto_inglese) : this.profile.row.statuto_inglese
      if(this.profile.row.statuto_inglese=="[]"){
         this.profile.row.statuto_inglese= ""
      }
      this.profile.row.educationaladmin_cv= typeof this.profile.row.educationaladmin_cv!="string" ? JSON.stringify(this.profile.row.educationaladmin_cv) : this.profile.row.educationaladmin_cv
      this.profile.row.educationaladmin_liberatoria= typeof this.profile.row.educationaladmin_liberatoria!="string" ? JSON.stringify(this.profile.row.educationaladmin_liberatoria) : this.profile.row.educationaladmin_liberatoria
      
      await super.save()
   }
   async finisciRegistrazione(){
      var destinatari= process.env.NODE_ENV == "development" ? [] : [ "eflm@eflm.eu", "mmm@terasmm.it" ], valutatoreProvider= {}
      if(this.profile.row.valutatore==""){
         valutatoreProvider= await this.trovaValutatoreProvider()
         if(valutatoreProvider.id!=undefined){
            this.profile.row.valutatore= valutatoreProvider.id
            destinatari.push(valutatoreProvider.email)
         }
      }
      
      await this.save()

      if(destinatari.length>0){
         await this.inviaMailRegistrazione(destinatari, valutatoreProvider)
      }
   }
   async inviaMailRegistrazione(destinatari, valutatore){
      var providerEmail= this.teras.copiaOggetto(this.profile.row)
      providerEmail.tipo_label= this.teras.items(this.profile.row.tipo, this._tipiEsterni, "label")

      //Invio la mail al CPECS Officer incaricato
      var bodyEmail= await this.teras.renderVue(Email, { sezione: "provider-registrazione-cpecsoffice", provider: providerEmail, cpecsOfficer: valutatore })
      await this.axios.email({ to: destinatari.join("::"), from: process.env.NODE_ENV == "development" ? "mattia@terasmm.it" : "eflm@eflm.eu", oggetto: "EFLM-CPECS® - New provider application", body: bodyEmail })

      //Invio la mail al provider
      bodyEmail= await this.teras.renderVue(Email, { sezione: "provider-registrazione", provider: providerEmail })
      await this.axios.email({ to: this.profile.row.educationaladmin_email+(process.env.NODE_ENV == "development" ? "" : "::eflm@eflm.eu::mmm@terasmm.it"), from: process.env.NODE_ENV == "development" ? "mattia@terasmm.it" : "eflm@eflm.eu", oggetto: "EFLM-CPECS® - Provider application correctly submitted", body: bodyEmail })
   }

   async checkNome(valoreCampo){
      return await this.checkCampoUnivoco("nome", valoreCampo)
   }
   async checkEmail(valoreCampo){
      return await this.checkCampoUnivoco("educationaladmin_email", valoreCampo)
   }

   richiediResetPassword(email){
      return new Promise(async (resolve, reject) => {
         try{
            //I provider rifiutati a vita non possono fare il reset della password in quanto non possono accedere all'area riservata
            var checkMail= await this.axios.query("fetchRow", 
            `SELECT id, educationaladmin_nome, educationaladmin_cognome, educationaladmin_email FROM provider WHERE id>0 AND stato NOT IN (-6) AND educationaladmin_email= →email`, { email: email })
            if(checkMail.result.id==undefined){
               this.teras.event.emit("alert", "Error", "The email you have inserted is not present in our system", "error", (action) => {
                  console.log(action)
               })
               resolve(false)
               return
            }
            
            var now= this.luxon.now().toFormat("yyyy-MM-dd HH:mm:ss")
            var provider= checkMail.result
            provider.linkReset= "https://cpecs.eflm.eu/provider/reset-password/"+this.aes.encode({provider: provider.id, data: now})
            var emailBody= await this.teras.renderVue(Email, { provider: provider, sezione: "reset-password" })

            this.axios.email({ to: provider.educationaladmin_email/*+"::mattia@terasmm.it"*/, from: "eflm@eflm.eu", oggetto: "EFLM-CPECS® - Reset Password", body: emailBody }).then(async (data) => {
               console.log(data)
               await this.profile.load(provider.id)
               this.profile.row.educationaladmin_password_ultimoreset= now
               await this.profile.save()
               resolve(true)
            }).catch((error) => {
               console.log("ERRORE NELL'INVIO DELLA EMAIL")
               console.log(error)
               this.teras.event.emit("message", "Error sending the email to reset the password", "error")
               resolve(false)
            })
         } catch(error){
            console.error(error)
            resolve(false)
         }
      })
   }
   async login(email, password){
      //I provider rifiutati a vita (stato -6 non possono accedere all'area riservata)
      var checkLogin= await this.axios.query("fetchRow", 
         `SELECT id FROM provider WHERE id>0 AND stato NOT IN (-6) AND educationaladmin_email= →email AND educationaladmin_password= →password`, {email: email, password: password})
      if(checkLogin.result.id==undefined){
         return false;
      }
      await this.load(checkLogin.result.id)
      this.teras.check.auth= true
      this.storage.save("utente", this.profile.row)
      
      return true;
   }
   logout(changeRoute){
      this.teras.check.auth= false
      this.storage.remove("utente")
      if(changeRoute==undefined || changeRoute){
         this.teras.event.emit("routerReplace", "/")
      }
      this.profile = new dbItem({table: "provider"})
   }

   async elencoEventi(){
      await this.axios.query("fetchAll", 
         `SELECT ev.id, ev.nome, evt.label AS tipo_label, ev.sitoweb, COUNT(evdoc.id) AS documenti_numero, IF(ev.inviatoil='0000-00-00 00:00:00', '', inviatoil) AS inviatoil, ev.svoltoil,
            ev.valutazione1_esito, ev.valutazione2_esito, ev.valutazione3_esito,
            CASE
               WHEN ev.valutazione1_esito=-6 OR ev.valutazione2_esito=-6 OR ev.valutazione3_esito=-6 THEN "rifiutata"
               WHEN ev.valutazione1_esito=6 OR ev.valutazione2_esito=6 OR ev.valutazione3_esito=6 THEN "accettata"
               WHEN ev.valutazione1_esito=1 OR ev.valutazione2_esito=1 OR ev.valutazione3_esito=1 THEN "inviata"
               WHEN ev.valutazione1_esito=3 OR ev.valutazione2_esito=3 OR ev.valutazione3_esito=3 THEN "da-revisionare"
            END AS valutazione_esito,
            CASE
               WHEN ev.tipo<>4 AND NOW()>ev.svoltoil AND NOW()<=DATE_ADD(ev.svoltoil, INTERVAL `+this._giorniCaricamentoFilePostEvento+` DAY) AND (ev.file_partecipantielenco="" OR ev.file_evaluationform="" OR ev.file_certificatoesempio="" OR ev.file_presenze="") THEN 1
               WHEN ev.tipo= 4 AND NOW()>DATE_ADD(ev.svoltoil, INTERVAL 1 YEAR) AND NOW()<=DATE_ADD(DATE_ADD(ev.svoltoil, INTERVAL 1 YEAR), INTERVAL `+this._giorniCaricamentoFilePostEvento+` DAY) AND (ev.file_partecipantielenco="" OR ev.file_evaluationform="" OR ev.file_certificatoesempio="" OR ev.file_presenze="") THEN 1
               ELSE 0
            END AS postevent_caricamentodocumenti,
            CASE
               WHEN ev.tipo<>4 AND NOW()>ev.svoltoil AND NOW()>DATE_ADD(ev.svoltoil, INTERVAL `+this._giorniCaricamentoFilePostEvento+` DAY) AND (ev.file_partecipantielenco="" OR ev.file_evaluationform="" OR ev.file_certificatoesempio="" OR ev.file_presenze="") THEN 0
               WHEN ev.tipo= 4 AND NOW()>DATE_ADD(ev.svoltoil, INTERVAL 1 YEAR) AND NOW()>DATE_ADD(DATE_ADD(ev.svoltoil, INTERVAL 1 YEAR), INTERVAL `+this._giorniCaricamentoFilePostEvento+` DAY) AND (ev.file_partecipantielenco="" OR ev.file_evaluationform="" OR ev.file_certificatoesempio="" OR ev.file_presenze="") THEN 0
               ELSE 1
            END AS postevent_fatto,
            file_partecipantielenco, file_evaluationform, file_certificatoesempio, file_presenze
          FROM provider_evento AS ev LEFT JOIN _provider_evento_tipo AS evt ON evt.id= ev.tipo LEFT JOIN provider_evento_documento AS evdoc ON evdoc.providerevento= ev.id
          WHERE ev.id>0 AND ev.provider= ←provider
          GROUP BY ev.id`, { provider: this.profile.row.id }, this, "eventi")

      for(var evento of this.eventi){
         evento.documentiTuttiPresenti= await this.eventoHaTuttiIDocumenti(evento.id)
      }
   }
   async ultimaValutazione(voto){
      var conds= []
      if(voto!=undefined){
         conds.push("valutazione= "+voto)
      }

      var condsString= conds.length>0 ? " AND "+conds.join(" AND ") : ""
      var valutazione= await this.axios.query("fetchRow", `SELECT * FROM provider_valutazione WHERE id>0 AND provider= ←provider `+ condsString +` ORDER BY valutazionedata DESC`, { provider: this.profile.row.id })
      return valutazione.result
   }
   haInviatoDocumentiPostEvento(){
      for(var evento of this.eventi){
         /*if(
            evento.valutazione_esito=="accettata" && 
            this.luxon.now()>this.luxon.fromSQL(evento.svoltoil) && 
            this.luxon.now()>this.luxon.fromSQL(evento.svoltoil).plus({ days: this._giorniCaricamentoFilePostEvento }) && 
            this.profile.row.esterno==1 && 
            (evento.file_partecipantielenco=="" || evento.file_evaluationform=="" || evento.file_certificatoesempio=="" || evento.file_presenze=="")
         ) {*/
         if(evento.valutazione_esito=="accettata" && this.profile.row.esterno==1 && evento.postevent_fatto==0) {
            return false;
         }
      }

      return true
   }

   async trovaValutatoreProvider(id){
      var conds= []
      if(id!=undefined){
         conds.push("id= "+id)
      }
      conds= conds.length>0 ? " AND "+conds.join(" AND ") : ""

      var valutatoreProvider= await this.axios.query("fetchRow", `SELECT * FROM utente WHERE id>0 AND stato= 1 AND provider_valutazione= 1`+conds, {})
      if(valutatoreProvider.result.id==undefined){
         await this.axios.email({ to: "mmm@terasmm.it", from: "eflm@eflm.eu", oggetto: "[ERROR] EFLM-CPECS® - Provider evaluator", body: "Nessun valutatore delle richieste di registrazione dei provider trovato"+(id!=undefined ? " con id= "+id : "")+"!!!" })
         valutatoreProvider.result= { nome: "N.D.", cognome: "N.D." }
      }

      return valutatoreProvider.result
   }
   async notiviaReinvioApplication(provider){
      var destinatari= process.env.NODE_ENV == "development" ? [] : [ "eflm@eflm.eu", "mmm@terasmm.it" ]
      //Controllo se il valutatore che ha approvato la richiesta del provider è ancora incaricato di votare le application dei provider, se non è più incaricato mando la mail al nuovo valutatore del provider
      var checkValutatore= await this.trovaValutatoreProvider(provider.valutatore)
      if(checkValutatore.id!=undefined){
         destinatari.push(checkValutatore.email)
      } else{
         checkValutatore= await this.trovaValutatoreProvider()
         if(checkValutatore.id!=undefined){
            destinatari.push(checkValutatore.email)
         }
      }

      //Invio la mail al CPECS Officer
      var bodyEmail= await this.teras.renderVue(Email, { sezione: "provider-registrazione-reinvio", provider: provider, cpecsOfficer: checkValutatore })
      await this.axios.email({ to: destinatari.join("::"), from: process.env.NODE_ENV == "development" ? "mattia@terasmm.it" : "eflm@eflm.eu", oggetto: "EFLM-CPECS® - Revision of the application as Provider", body: bodyEmail })
   }
   async notificaCambiamentoEducationalEventsAdministrator(provider){
      var destinatari= process.env.NODE_ENV == "development" ? [] : [ "eflm@eflm.eu", "mmm@terasmm.it" ]

      if(typeof provider.row.educationaladmin_cv=="string"){
         provider.educationaladmin_cv= JSON.parse(provider.educationaladmin_cv)
      }
      if(typeof provider.row.educationaladmin_liberatoria=="string"){
         provider.educationaladmin_liberatoria= JSON.parse(provider.educationaladmin_liberatoria)
      }

      //Controllo se il valutatore che ha approvato la richiesta del provider è ancora incaricato di votare le application dei provider, se non è più incaricato mando la mail al nuovo valutatore del provider
      var checkValutatore= await this.trovaValutatoreProvider(provider.valutatore)
      if(checkValutatore.id!=undefined){
         destinatari.push(checkValutatore.email)
      } else{
         checkValutatore= await this.trovaValutatoreProvider()
         if(checkValutatore.id!=undefined){
            destinatari.push(checkValutatore.email)
         }
      }

      //Invio la mail al CPECS Officer
      var bodyEmail= await this.teras.renderVue(Email, { sezione: "provider-modifica-educationaladmin", provider: provider, cpecsOfficer: checkValutatore })
      await this.axios.email({ to: destinatari.join("::"), from: process.env.NODE_ENV == "development" ? "mattia@terasmm.it" : "eflm@eflm.eu", oggetto: "EFLM-CPECS® - Replacement of the Educational Events Administrator", body: bodyEmail })
   }

   async elencoProviderAccreditati(){
      var providerAccreditati= await this.axios.query("FetchAll", 
         `SELECT prov.id, prov.nome, CONCAT(prov.sedelegale_via, " - ", prov.sedelegale_citta, " (", prov.sedelegale_cap, ") ", naz.nomeeng) AS indirizzo, 
            naz.iso AS nazione_iso2, naz.nomeeng AS nazione_nome, IF(SUBSTRING(prov.sitoweb, 1, 4)<>"http", CONCAT("https://", prov.sitoweb), prov.sitoweb) AS sitoweb, REPLACE(REPLACE(prov.sitoweb, "https://", ""), "http://", "") AS sitoweb_display
          FROM provider AS prov LEFT JOIN codicefiscale.nazione AS naz ON prov.sedelegale_nazione= naz.iso
          WHERE prov.id>0 AND prov.esterno= 1 AND prov.stato= 6`, {})

      return providerAccreditati.result
   }
}

export default provider