import { createApp } from "vue/dist/vue.esm-bundler"; 
import { h, reactive } from "vue"
import App from "./App.vue"


/* Inizio Router */
import { createVueRouter } from "./router"
/* Fine Router */

/* Inizio ElementPlus */
import { ElLoading, ElMessage, ElMessageBox, dayjs } from "element-plus";
//Importa il primo giorno della settimana nei picker al Lunedì
dayjs().$locale().weekStart = 1;
import "./css/element-var.css"
/* Fine ElementPlus */

/* Inizio Luxon */
//https://moment.github.io/luxon/#/moment
//https://moment.github.io/luxon/#/formatting?id=table-of-tokens
import { DateTime as luxon, Settings as luxonSettings } from "luxon"
luxonSettings.defaultLocale= "en"
luxonSettings.defaultZone= "Europe/Rome"
/* Fine Luxon */

/* Inizio TerAs */
import terasImp from "./teras"
var teras= reactive(terasImp)
import aes from "./teras/aes"
import axios from "./teras/tAxios"
import storage from "./teras/tStorage" 

import "./css/big.css"
/* Fine TerAs */

/* Inizio TerAs-event */
/*teras.event.on("checkAuth", async (from,to) =>{
   console.log("CHECK AUTH")
   if(storage.exist("utente")){
      console.log("YES, PRESENTE UTENTE IN LOCAL STORAGE....LO RICARICO DA LI..."+to.path)
      teras.utente = storage.load("utente")
      teras.event.emit("login",to.path)
   }else{
      teras.check.auth = false
      teras.event.emit("errore",-1,"È necessario effettuare l\'accesso prima di poter accedere alla pagina")
      if(from.path!="/login")
         router.replace("/login")
   }
})*/
teras.event.on("checkFirst", async function(from, to) {
   var providerTipi= await axios.query("fetchAll", 
      `SELECT id, id AS valore, label, esterno FROM _provider_tipo WHERE id>0 ORDER BY peso, label`, {})

   var providerEsterniTipi= [], providerInterniTipi= []
   providerTipi.result.forEach(element => {
      if(element.esterno==0){
         providerInterniTipi.push(element)
      } else{
         providerEsterniTipi.push(element)
      }
   });
   provider._tipiEsterni= providerEsterniTipi
   provider._tipiInterni= providerInterniTipi

   var nazioni= await axios.query("fetchAll", 
      `SELECT id, iso AS valore, nomeeng AS label FROM codicefiscale.nazione WHERE id>0 AND iso NOT IN ("", "-") ORDER BY nomeeng`, {})
   provider._nazioni= nazioni.result
   evento._nazioni= nazioni.result

   var documentiTipi= await axios.query("fetchAll", 
      `SELECT * FROM _provider_evento_documenti_tipo WHERE id>0 ORDER BY label`, {})
   eventoDocumento._tipi= documentiTipi.result
   provider._documentiEventiTipi= documentiTipi.result
   var lingue= await axios.query("fetchAll", 
      `SELECT id, id AS valore, label FROM _provider_evento_lingua WHERE id>0 ORDER BY label`, {})
   evento._lingue= lingue.result
   var livelliEducazione= await axios.query("fetchAll", 
      `SELECT id, id AS valore, label FROM _provider_evento_livelloeducazione WHERE id>0 ORDER BY peso`, {})
   evento._livelliEducazione= livelliEducazione.result
   var partecipantiLivello= await axios.query("fetchAll", 
      `SELECT id, id AS valore, label FROM _provider_evento_partecipantilivello WHERE id>0 ORDER BY peso`, {})
   evento._partecipantiLivello= partecipantiLivello.result
   var rilevamentopresenzeTipologie= await axios.query("fetchAll", 
      `SELECT id, id AS valore, label FROM _provider_evento_rilevamentopresenzatipologia WHERE id>0 ORDER BY peso`, {})
   evento._rilevamentopresenzeTipologie= rilevamentopresenzeTipologie.result
   var tipi= await axios.query("fetchAll", 
      `SELECT id, id AS valore, label FROM _provider_evento_tipo WHERE id>0 ORDER BY peso, label`, {})
   evento._tipi= tipi.result

   var mezziPagamento= await axios.query("fetchAll", 
      `SELECT id, id AS valore, nome_eng AS label, UPPER(nome_eng) AS label_upper FROM _fiscale_mezzopagamento WHERE id>0 ORDER BY label`, {})
   fiscale._mezziPagamento= mezziPagamento.result
   
   teras.check.first = true
   
   var pathToGo= to.path
   console.log("CHECK AUTH")
   if(storage.exist("utente")){
      console.log("YES, PRESENTE UTENTE IN LOCAL STORAGE....LO RICARICO DA LI..."+to.path)
      var utente = storage.load("utente")
      if(!await provider.login(utente.educationaladmin_email, utente.educationaladmin_password)){
         //Se le credenziali non combaciano più effettuo il logout
         provider.logout(false)
         if(to.meta.requiresAuth){
            pathToGo= "/provider/login"
         }
      }
   } else{
      teras.check.auth = false
      if(from.path!="/provider/login" && to.meta.requiresAuth){
         pathToGo= "/provider/login"
      }
   }
   router.replace(pathToGo)
})
teras.event.on("appReady", function() {
   console.log("appReady appReady appReady appReady appReady appReady appReady")
   router.push(teras.app.routeInitial)
})
teras.event.on("routerReplace", function(to) {
   router.replace(to)
})
teras.event.on("appExit", function() {
   
})

teras.event.on("setSeoInformation", async function(page){
   new Promise((resolve) => {
      teras.event.emit("removeSeoInformationFromPrevPage", resolve)
   }).then(async (value) => {
      //Imposto il meta title e description per la pagina statica recuperata
      for(var i=0; i<page.head.children.length; i++){
         if(page.head.children[i].attributes[0].nodeName=="title"){
            console.log("SEO title: " +page.head.children[i].attributes[0].nodeValue)
            var meta = document.createElement("meta");
            meta.title= page.head.children[i].attributes[0].nodeValue
            document.getElementsByTagName("head")[0].appendChild(meta);
         }
         if(page.head.children[i].attributes[0].nodeName=="name" && page.head.children[i].attributes[0].nodeValue=="description" && 
            page.head.children[i].attributes[1]!=undefined && page.head.children[i].attributes[1].nodeName=="content"){
            console.log("SEO description: " +page.head.children[i].attributes[1].nodeValue)
            var meta = document.createElement("meta");
            meta.name="description"
            meta.content = page.head.children[i].attributes[1].nodeValue
            document.getElementsByTagName("head")[0].appendChild(meta);
         }
      }
   })
})
teras.event.on("removeSeoInformationFromPrevPage", function(callback){
   //Salvo dentro l'array nodeToRemove i tag meta della pagina statica precedente che sono da rimuovere per inserire quelli nuovi
   var nodeToRemove= []
   for(var i=0; i<document.getElementsByTagName("head")[0].children.length; i++){
      var node= document.getElementsByTagName("head")[0].children[i]
      if(node.localName=="meta" && node.attributes[0].nodeName=="title"){
         nodeToRemove.push(node)
      }
      if(node.localName=="meta" && node.name=="description"){
         nodeToRemove.push(node)
      }
   }
   for(var i=0; i<nodeToRemove.length; i++){
      document.getElementsByTagName("head")[0].removeChild(nodeToRemove[i])
   }
   if(callback!=undefined){
      //console.log("callback")
      callback(true)
   }
})

window.addEventListener("resize",() => { 
   teras.getDevice()
   teras.event.emit("appResize")
})
window.addEventListener("scroll",() => { 
   teras.getDevice()
   teras.event.emit("appScroll")
})

/* Fine TerAs-event */

/* Inizio Vue-3 */

const app= createApp({
   render: () => h(App)
})

app.config.globalProperties.$teras= teras
app.config.globalProperties.$aes = aes
app.config.globalProperties.$axios = axios
app.config.globalProperties.$luxon = luxon
app.config.globalProperties.$storage = storage

const router = createVueRouter(app)
app.use(router)
app.use(ElLoading)
app.use(ElMessage)
app.use(ElMessageBox)


import providerImp from "@/models/provider";
var provider= reactive(new providerImp({ aes: aes, teras: teras, axios: axios, luxon: app.config.globalProperties.$luxon, storage: storage }))

import eventoImp from "@/models/evento";
var evento= reactive(new eventoImp({ aes: aes, teras: teras, axios: axios, luxon: app.config.globalProperties.$luxon, storage: storage, provider: provider }))

import eventoDocumentoImp from "@/models/eventoDocumento";
var eventoDocumento= reactive(new eventoDocumentoImp({ aes: aes, teras: teras, axios: axios, luxon: app.config.globalProperties.$luxon, storage: storage, provider: provider, evento: evento }))

import fiscaleImp from "@/models/fiscale";
var fiscale= reactive(new fiscaleImp({ aes: aes, teras: teras, axios: axios, luxon: app.config.globalProperties.$luxon, storage: storage, provider: provider }))

app.config.globalProperties.$evento= evento
app.config.globalProperties.$eventoDocumento= eventoDocumento
app.config.globalProperties.$fiscale= fiscale
app.config.globalProperties.$provider= provider

app.mount("#app")

teras.vueAppInstance= app

/* Fine Vue-3 */
