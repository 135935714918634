const EventEmitter = require("events").EventEmitter

class basic{

   constructor (args) {
      this.event = new EventEmitter()
      this.teras= args.teras

      this.luxon = args.luxon
      this.aes= args.aes
      this.axios= args.axios
      this.storage= args.storage
      this.router= args.router
      if(args.provider!=undefined){
         this.provider= args.provider
      }
      if(args.evento!=undefined){
         this.evento= args.evento
      }
      
      this.profile= {}
      this.oldrow= {}

      this._giorniCaricamentoFilePostEvento= 60

      this._documentiEventiTipi= []
      this._nazioni= []
      this._mezziPagamento= []
      this._sino= [ { id: 1, valore: 1, label: "YES"}, { id: 0, valore: 0, label: "NO"} ]
      
      return this
   }

   async new(){
      await this.profile.new()
      this.oldrow= this.teras.copiaOggetto(this.profile.row)
   }
   async load(id){
      await this.profile.load(id)
      this.oldrow= this.teras.copiaOggetto(this.profile.row)
   }

   async loadEncode(id){
      await this.profile.loadEncode(id)
      this.oldrow= this.teras.copiaOggetto(this.profile.row)
   }

   async save(){
      await this.profile.save()
      //await this.registraLogCambiamento()
   }

   async loadDocumentiEvento(idEvento){
      var documenti= await this.axios.query("fetchAll", 
         `SELECT evdoc.id, evdoc.nome, evdoc.tipologia, evdoc.file, evdoctipo.label AS tipo_label
         FROM provider_evento_documento AS evdoc LEFT JOIN _provider_evento_documenti_tipo AS evdoctipo ON evdoctipo.id= evdoc.tipologia 
         WHERE evdoc.id>0 AND evdoc.providerevento= ←ev`, { ev: idEvento })

      return documenti.result
   }
   async eventoHaTuttiIDocumenti(idEvento){
      var documenti= await this.loadDocumentiEvento(idEvento)
      for(var tipoDocumento of this._documentiEventiTipi){
         if(!this.controllaTipologiaDocumento(tipoDocumento, documenti)){
            return false;
         }
      }

      return true;
   }
   controllaTipologiaDocumento(tipoDocumento, documenti){
      if(tipoDocumento.mindocumenti==0){
         return true;
      }

      var numeroDocumentiTipo= 0
      for(var documento of documenti){
         if(documento.tipologia==tipoDocumento.id){
            numeroDocumentiTipo++
         }
      }

      if(tipoDocumento.mindocumenti>numeroDocumentiTipo || (tipoDocumento.maxdocumenti>0 && numeroDocumentiTipo>tipoDocumento.maxdocumenti)){
         return false;
      }

      return true;
   }

   buildFile(name, fileString){
      var fileJson= JSON.parse(fileString)[0]
      var file= {}
      file.name= name
      file.url= fileJson.url
      file.extension= fileJson.url.substring(fileJson.url.lastIndexOf(".")+1)
      switch(file.extension.toLowerCase()){
         case "doc":
         case "docx":
            file.extensionimg= "doc"
         break;
         case "xsl":
         case "xslx":
         case "xls":
         case "xlsx":
            file.extensionimg= "spreadsheet"
         break
         case "pdf":
            file.extensionimg= "pdf"
         break;
         case "png":
         case "webp":
         case "jpg":
         case "jpeg":
         case "bmp":
            file.extensionimg= "image"
         break;
         case "zip":
            file.extensionimg= "zip"
         break;
         default:
            file.extensionimg= "doc"
            this.axios.email({ to: "mattia@terasmm.it", from: "eflm@eflm.eu", oggetto: "Tipo File NON Gestito in CPECS Provider Profile", body: "Il tipo di file "+ file.extension+" NON è gestito per la visualizzazione della preview dell'immagine" })
         break;
      }
      return file
   }

   cercaElementoSelect(query, arrayToSearch, propToSearch){
      var arrayToPush= []
      if (query !== "") {
         arrayToSearch.forEach(element => {
            if(element[propToSearch].toString().toLowerCase().includes(query.toString().toLowerCase())){
               arrayToPush.push(element)
            }
         })
      } 
      return arrayToPush
   }

   getArrayOfProp(array, propName){
      var myArrayOfProp= []
      array.forEach(element => {
         myArrayOfProp.push(element[propName])
      })
      return myArrayOfProp
   }

   calcolaPercorsoFattura(idFiscale, tipo){
      var url= "/www/cpecs.eflm.eu/fiscale/fatture/"+this.aes.encode(idFiscale)+".pdf"
      if(tipo=="pubblico"){
         url= url.replace("/www/", "https://")
      }
      return url
   }

}
export default basic