<template>
   <div v-loading.fullscreen.lock="!$teras.app.ready" :class="'device-'+$teras.app.device+' '+$route.name">
      <el-config-provider value-on-clear="">
         <site-header />

         <div class="app-main row">
            <div id="main" class="col s12">
               <router-view name="main" />
            </div><!-- end main -->
         </div><!-- end container -->
         
         <site-footer /> 
      </el-config-provider>
   </div> <!-- end app -->
</template>
<script>
import { ElConfigProvider } from "element-plus"
import { defineAsyncComponent } from "vue"
export default {
   name: "app",
   components: {
      ElConfigProvider,

      SiteHeader: defineAsyncComponent(() => import("@/components/Site-header.vue")),
      SiteFooter: defineAsyncComponent(() => import("@/components/Site-footer.vue")),
   },
   data() {
      return {
         loading: {}
      }
   },
   created(){

      this.$teras.event.on("notify", (titolo,messaggio,tipo) => {
         titolo = (typeof messaggio == "string") ? titolo : "Errore non specificato"
         messaggio = (typeof messaggio == "string") ? messaggio : "Siamo spiacenti si è verificato un errore, la preghiamo di verificare i dati inseriti!"
         tipo = (typeof tipo == "string") ? tipo : "error"
         this.$notify({type: tipo, title: titolo, message: messaggio})
      })

      this.$teras.event.on("loading", (messaggio) => {
         messaggio = (typeof messaggio == "string") ? messaggio : "Caricamento in corso, non ricaricare la pagina....."
         console.log("messaggio: "+ messaggio)
         this.loading = this.$loading({lock: true, text: messaggio, background: "rgba(0, 0, 0, 0.7)" })
      })
      this.$teras.event.on("loadingclose", () => {
         this.loading.close()
      })

      this.$teras.event.on("alert", (titolo, messaggio, tipo, callback) => {
         this.$alert(messaggio, titolo, { type: tipo, showClose: false, dangerouslyUseHTMLString: true }).then((action) => {
            if(callback!=undefined) callback(action)
         })
      })
   }
}
</script>