import { createWebHistory, createWebHashHistory, createRouter } from "vue-router";

const DownloadFile = () => import("@/components/Teras/Download");

const Home = () => import("@/components/Home");
const ChiSiamo = () => import("@/components/Chi-siamo");
const Risorse = () => import("@/components/Risorse");

const HowToApply = () => import("@/components/How-to-apply");
const CpecsFee = () => import("@/components/Fees");
const ProviderAccreditati = () => import("@/components/Provider-accreditati");

const ProviderRegistrazione = () => import("@/components/Provider/Registrazione");
const ProviderLogin = () => import("@/components/Provider/Login");
const ProviderResetPassword = () => import("@/components/Provider/Reset-password");

const ProviderHome = () => import("@/components/Provider/Home");
const ProviderProfilo = () => import("@/components/Provider/Profilo");
const ProviderEventoNuovo = () => import("@/components/Evento/Nuovo");
const ProviderEventoProfilo = () => import("@/components/Evento/Profilo");
const ProviderEventoRevisione = () => import("@/components/Evento/Revisione");
const ProviderEventoPost = () => import("@/components/Evento/Post-evento");

var mode = (process.env.NODE_ENV != "development") ? createWebHistory("/") : createWebHashHistory("/")

export const createVueRouter = (app) => {
   const router = createRouter({
      history: mode,

      scrollBehavior (to, from, savedPosition) {
         document.getElementById("app").scrollIntoView({ behavior: "smooth" });
      },

      routes: [
         { path: "/dwn/:file", name: "download-file", meta: { requiresEvent: false, requiresAuth: false, title: "Download file" }, components:{ main: DownloadFile } },
         { path: "/", name: "home", meta: { requiresEvent: false, requiresAuth: false, title: "" }, components: { main: Home } },
         { path: "/who-we-are", name: "chi-siamo", meta: { requiresEvent: false, requiresAuth: false, title: "Who we are" }, components: { main: ChiSiamo } },
         { path: "/resources", name: "resources", meta: { requiresEvent: false, requiresAuth: false, title: "Resources" }, components: { main: Risorse } },

         { path: "/how-to-apply", name: "how-to-apply", meta: { requiresEvent: false, requiresAuth: false, title: "How to apply" }, components: { main: HowToApply } },
         { path: "/fees", name: "fee", meta: { requiresEvent: false, requiresAuth: false, title: "Fees" }, components: { main: CpecsFee } },
         { path: "/accredited-provider", name: "provider-accreditati", meta: { requiresEvent: false, requiresAuth: false, title: "Accredited Provider" }, components: { main: ProviderAccreditati } },

         { path: "/provider/registration", name: "provider-registrazione", meta: { requiresEvent: false, requiresAuth: false, title: "Provider Registration" }, components: { main: ProviderRegistrazione } },
         { path: "/provider/reset-password", name: "provider-reset-password", meta: { requiresEvent: false, requiresAuth: false, title: "Provider Reset Password" }, components: { main: ProviderResetPassword } },
         { path: "/provider/reset-password/:action", name: "provider-reset-password-action", meta: { requiresEvent: false, requiresAuth: false, title: "Provider Reset Password" }, components: { main: ProviderResetPassword } },
         { path: "/provider/login", name: "provider-login", meta: { requiresEvent: false, requiresAuth: false, title: "Provider Login" }, components: { main: ProviderLogin } },
         
         { path: "/provider/home", name: "provider-home", meta: { requiresEvent: false, requiresAuth: true, title: "Provider Home" }, components: { main: ProviderHome } },
         { path: "/provider/profile", name: "provider-profile", meta: { requiresEvent: false, requiresAuth: true, title: "Provider Profile" }, components: { main: ProviderProfilo } },
         
         { path: "/provider/new-event", name: "provider-nuovoevento", meta: { requiresEvent: false, requiresAuth: true, title: "New Event" }, components: { main: ProviderEventoNuovo } },
         { path: "/provider/event-request/:evento/:step?", name: "provider-eventomodifica", meta: { requiresEvent: false, requiresAuth: true, title: "Event" }, components: { main: ProviderEventoNuovo } },
         { path: "/provider/event-review/:evento", name: "provider-eventorevisione", meta: { requiresEvent: false, requiresAuth: true, title: "Event Review" }, components: { main: ProviderEventoRevisione } },
         { path: "/provider/event-request/:evento/post-event", name: "provider-eventopost", meta: { requiresEvent: false, requiresAuth: true, title: "Post Event" }, components: { main: ProviderEventoPost } },

         { path: "/provider/event/:evento", name: "provider-eventoprofilo", meta: { requiresEvent: false, requiresAuth: true, title: "Event" }, components: { main: ProviderEventoProfilo } },

         { path: "/:catchAll(.*)", redirect: "/" }
      ]
   })

   router.beforeEach(async (to, from, next) => {
      var teras = app.config.globalProperties.$teras
      let error = false
      teras.app.drawer = false
      //to.query contiene le variabili passate in modo standard es. ?variabile=valore
      //to.params contiene le variabili passate in vuejs passate in base a routes[]
      console.log("\nbeforeEach \ncheckFirst: "+router.checkFirst+"\nfrom: "+from.path+"\nto: "+to.path+"\n\n")
      teras.router.from = from.path
      teras.router.to = to.path

      if (!teras.check.first){
         console.log("SONO RICHIESTE LE OPERAZIONI DI PRIMO AVVIO")
         teras.event.emit("checkFirst", from, to)
         next(false)
         return
      }
      
      /*console.log("AUTH "+teras.app.auth +" AND error "+error)
      if (!error && to.matched.some(record => record.meta.requiresAuth) && !teras.check.auth){
         console.log("È RICHIESTO UTENTE E NON È ANCORA VERIFICATO")
         teras.event.emit("checkAuth", from, to)
         error = true
      }*/
    
      if(error){
         next(false)
      }else{
         console.log("beforeEach next")
         window.scrollTo(0,0)
         teras.app.ready = true
         if(to.name=="home"){
            document.title= teras.pageTitleBase
         } else{
            document.title= to.meta.title+" | "+teras.pageTitleBase
         }
         
         next()
         teras.event.emit("routerChange", to)
      }
   })

   return router
}
