import teras from "@/teras"
import convert from "xml-js"
import axios from "axios"
import aes from "./aes"

class tAxios {
   constructor () {
      this.teras = teras
      this.apiUrl = teras.apiUrl
      this.proxy = false

      this.axios = axios.create({
         headers: {"X-Request-ID": teras.name,"X-aes": ((process.env.NODE_ENV != "development"  ) ? 1 : 0)},
         withCredentials:  ((process.env.NODE_ENV != "development") ? true : false),
         validateStatus: function(status) {return status >= 200 && status < 300 && status != 210 },
         transformResponse: [(data) => {
            if (typeof data === "string") {
               try{
                  data = JSON.parse(data)
                  if (typeof data.isencrypt === "string") {
                  data = JSON.parse(aes.decode(data.isencrypt))
                  } 
               }catch(e){
                  throw {message: "La risposta non è una stringa JSON valida",response:data}
               }
            }
            return data;
         }],
         transformRequest: [(data, headers) => {
            if(typeof data !="undefined"){
               if(typeof data["_url"] !="undefined"){
                  headers["X-Request-URL"] = data["_url"]
                  delete data["_url"]
                  console.log(headers)
               }
               data["_token"] = headers["X-Request-ID"]
               if(process.env.NODE_ENV != "development"){
                  const isencrypt = aes.encode(JSON.stringify(data))
                  data = "{\"isencrypt\": \""+isencrypt+"\"}"
               } else{
                  data = JSON.stringify(data)
               }
            }
            return data
         }]
      })
      //this.axios.defaults.headers = {"X-Request-ID": this.teras.name,"X-aes": 0}
      
      this.rawdata = {}
      this.sessionID = ""
      this.mysql = teras.mysql

      teras.event.on("getDevice", () => {
         //console.log("getDevice from axios")
      })
   }
  
   get(url,data){
      return new Promise((resolve, reject) => {
         this.axios.get(url,data).then(rensponse => {resolve(rensponse)}).catch(error=>{reject(error)})
      })
   }
   post(url,data){
      let proxy = (arguments.length>2) ? arguments[2] : this.proxy
      if(proxy) {
         data["_url"] = url
         url = "/2019/proxy/"+url//+JSON.stringify(data)
      }  
      return new Promise((resolve, reject) => {
         this.axios.post(url,data).then(rensponse => {resolve(rensponse)}).catch(error=>{
            console.error("proxy is "+proxy)
            if(proxy){
               reject(error)
            }else{
               resolve(this.post(url,data,true))
            }
         })
      })
   }

   filterItems(value,items,campo){
      if(typeof items !="undefined" && items.length>0)
         for (let item of items)
            if(item.id == value) 
               return item[campo]
            return ""
   }

   logout(){
      return new Promise((resolve, reject) => {
         this.get(this.apiUrl+"vue/logout").then((response) =>  {
            resolve(response)
         }).catch((error) => {
            console.log("ERRORE NELL'INVIO DELLA LOGOUT")
            console.log(error.message)
            reject(error)
         })
      })
   }
   page(name){
      return new Promise((resolve, reject) => {
         this.get(teras.urlsite+"/page/"+name+".html"+"?"+new Date().getTime(),{withCredentials:false, responseType: "document"}).then(function (response) {
            resolve({
               head: response.request.response.head,
               title: response.request.response.body.children.item(0).innerHTML,
               testo: response.request.response.body.children.item(1).innerHTML,
               extra: response.request.response.body.children.item(2).innerHTML
            })
         }).catch((error) => {
         console.log("ERRORE NEL RECUPERO DELLA PAGINA")
         console.log(error.message)
         reject(error)
         })
      })
   }

   pageSave(page,url){
      return this.post(this.apiUrl+"vue/savepage",{page:page,url:url}).then(function (response) {return response.data.data})
   }

   /* INVIO EMAIL */
   email(emailData){
      return new Promise((resolve, reject) => {
         emailData = this.teras.objmerge({
            to:"mirko@terasmm.it",
            from:"info@terasmm.it",
            oggetto:"Nessun Oggetto",
            body:"Nessun testo inviato",
            //css: this.teras.urlsite+"css/email.css"
            css: "https://cpecs.eflm.eu/css/email.css"
         }, emailData)
         this.post(this.apiUrl+"vue/email",emailData).then((response) =>  {
            console.log("EMAIL INVIATA")
            console.log(response.data)
            resolve(response.data.data)
         }).catch((error) => {
            console.log("ERRORE NELL'INVIO DELLA EMAIL")
            console.log(error.message)
            reject(error)
         })
      })
   }

   /* CREA PDF FROM XSL */
   pdf(fileXsl,filePdf,data){
      if(typeof data == "object")
         data = convert.js2xml(data, {compact:true,ignoreComment: true, spaces: 4})
      if(typeof data == "undefined")
         throw {message: "Bisogna passare un oggetto JS o una stringa xml",response:data}
      

      return new Promise((resolve, reject) => {
         this.post(this.apiUrl+"vue/pdf",{pdf:filePdf, xsl:fileXsl, xml:data}).then((response) =>  {
            resolve(response)
         }).catch((error) => {
            reject(error)
         })
      })
   }
  
   query(fetch,query,params){
      return new Promise((resolve, reject) => {
         var mySql ={
            adapter: this.mysql.adapter,
            database: this.mysql.database,
            sqlFetch: fetch,
            sqlQuery: query,
            sqlParams: params
         }
         var argumentsLength = arguments.length;
         if(arguments.length>3)
            var param2set = arguments[3]
         if(arguments.length>4)
            var key2set = arguments[4]
         
         //se storage is true allora provo a caricare il valore della variabile da storage e non da mysql
         var storage =  (arguments.length>5) ? arguments[5] : false
         //se step is true allora caricata la variabile setto this.teras.app.step+1
         var step =  (arguments.length>6) ? arguments[6] : false

         if(storage && this.teras.exist(key2set)){
            console.log("++++++CARICO "+key2set+" DA STORAGE++++")
            param2set[key2set] = this.teras.load(key2set)
            if(step)
               this.teras.app.step++
         }else{
            console.log("++++++CARICO "+key2set+" DA QUERY++++")
            //console.log(arguments)

            this.post(this.apiUrl+this.mysql.composer+"/query",mySql).then((response) =>  {
               var api = response.data
               if(argumentsLength > 3 && typeof param2set != "undefined"){
                  if(typeof key2set != "undefined"){
                     param2set[key2set] = api.data.result
                  }else{
                     param2set = api.data.result
                  }
               }
               if(storage)
                  this.teras.save(key2set,api.data.result)
               if(step)
                  this.teras.app.step++
               resolve(api.data)
            }).catch((error) => {
               console.log("ERROR at query")
               console.log(error)
               reject(error)
            })

         }
      })
   }

   saveRow(row,table){
      return new Promise((resolve, reject) => {
         var mySql ={
            adapter: this.mysql.adapter,
            database: this.mysql.database,
            table:table,
            row: row
         } 
         this.post(this.apiUrl+this.mysql.composer+"/save",mySql).then((response) => {
            console.log("FINITO SAVE ROW")
            resolve(response.data)
         }).catch((error) => {
            console.log("ERROR at insert rows")
            console.log(this.mysql)
            console.log(this.apiUrl)
            reject(error)
         }) 
      })
   }

   insertRows(rows,table){
      return new Promise((resolve, reject) => {
         var mySql ={
            db: this.mysql.db,
            table:table,
            rows: rows
         }
         this.post(this.apiUrl+this.mysql.composer+"/insertrows",mySql).then((response) =>  {
            resolve(response.data)
         }).catch((error) => {
            console.log("ERROR at insert rows")
            console.log(error)
            reject(error)
         })
      })
   }  

   deleteRow(value,table,campo="id"){
      return new Promise((resolve, reject) => {
         var mySql ={
            adapter: this.mysql.adapter,
            database: this.mysql.database,
            sqlFetch: "fetchDelete",
            sqlQuery: "DELETE FROM ←table WHERE id=→value",
            sqlParams: {table:table,campo:campo,value:value}
         }
         this.post(this.apiUrl+this.mysql.composer+"/query",mySql).then((response) =>  {
            var api = response.data
            resolve(api.data)
         }).catch((error) => {
            console.log("ERROR at delete row")
            console.log(error)
            
            reject(error)
         })
      })
   }

}

export default new tAxios
